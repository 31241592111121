import "../Styles/Story.scss"

// React imports
import { React, useEffect } from "react";
import { Helmet } from "react-helmet";

// Images used
import headshot from "../Images/headshot.png"

let createTimepoint = (year, title, description) => {
    return (
        <li className="timepoint">
            <div className="point"></div>
            <div className="content">
                <div className="header">
                    <h1>{title}</h1>
                    <div className="year-container">
                        <span className="year">{year}</span>
                    </div>
                </div>
                <span className="description">{description}</span>
            </div>
        </li>
    )
};

export default function Story() {
    useEffect(() => {
        const contents = document.querySelectorAll(".content");
        const display = () => {
            const triggerBottom = (window.innerHeight / 5) * 4;

            contents.forEach((content) => {
                const topOfContent = content.getBoundingClientRect().top;

                if (topOfContent < triggerBottom) {
                    content.classList.add("show")
                } else {
                    content.classList.remove("show")
                }
            })
        };

        display();

        window.addEventListener("scroll", display)
    })

    return (
        <>
            <Helmet>
                <title>Peko - Story</title>
                <meta property="og:title" content="Peko - Story" />
                <meta property="og:description" content="The story behind the creation and evolution of Peko, a crossplatform framework allowing for the ease of developing applications which can run on all major platforms." />
                <meta name="description" content="The story behind the creation and evolution of Peko, a crossplatform framework allowing for the ease of developing applications which can run on all major platforms." />
                <meta property="og:url" content="https://pekoui.com/story" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div id="story-entrance">
                <div id="text">
                    <span id="title">The Peko Story</span>
                    <span id="quick-description">How did a 16 year old make the developer dream become a reality?</span>
                </div>
            </div>
            <div className="creator">
                <img alt="the creator of Peko, Preston Brown" src={headshot}></img>
                <div className="description">
                    <h1>Hi, I'm Preston!</h1>
                    <span>I am the developer and visionary behind Peko. Read through the timeline below to learn the story of my development journey and how Peko was born.</span>
                </div>
            </div>
            <ul id="timeline">
                {createTimepoint(
                    2018, 
                    "Getting a basis of coding", 
                    "I started my coding journey by learning the fundamentals of programming through the online block-coding platform Scratch. I designed games, physics engines, and other captivating projects to push my basis of coding."
                )}
                {createTimepoint(
                    2020, 
                    "Expanding coding knowledge", 
                    "After some time developing software with blocks, I wanted to push my knowledge into the industry standard, hand written code. I started working with javascript to create simple graphics and physics engines."
                )}
                {createTimepoint(
                    2021, 
                    "Game development", 
                    "After working with Javascript, I wanted to adventure further into game development and physics, working with the Unity game engine, C# language, and Blender 3d modeling software to design simple games."
                )}
                {createTimepoint(
                    2021, 
                    "UI Development", 
                    "After working with game development, I wanted to learn more. So I started utilizing different frameworks in Python to create UIs, such as the TKinter framework and Flask."
                )}
                {createTimepoint(
                    2022, 
                    "Idea of Peko was born", 
                    "After vigourously researching, I failed to find a UI framework which satisfied my needs. I wanted a framework with the ability to create a web-based UI while also being cross-platform and easily built. The closest options had downfalls such as complex build steps or lack of cross-platform capabilities. Peko was the solution to this problem."
                )}
                {createTimepoint(
                    2022, 
                    "First iteration of Peko", 
                    "Utilizing C and C++ in conjunction with the LLVM framework, I designed the first Pekoscript compiler, but this compiler lacked complexity and was extremely bug ridden.")}
                {createTimepoint(
                    2022, 
                    "Second iteration of Peko", 
                    "This iteration of Peko was still built in C++, but instead employed an interpreter rather than a compiler. This interpreter was decent, but it still lacked the complexity I was looking for, was still bug prone, and lacked the most important ability to be cross-COMPILED."
                )}
                {createTimepoint(
                    2023, 
                    "Third iteration of Peko", 
                    "To enable the compiler to be more secure, I switched to the Rust language for this iteration. This iteration also went back to being a compiler, and was much closer to the level of complexity I was searching for. Additionally, this was the first iteration with full command line tools, IDE tools, and cross-platform capability. But it was still bug prone and not at the level of complexity I sought after."
                )}
                {createTimepoint(
                    2023, 
                    "Peko website and social media created", 
                    "In an attempt to spread awareness about Peko, I created an online presence comprising of various social media accounts and a website. Despite this, I felt Peko wasn't quite ready and still required more work, so I went back to the thinking board to further improve the platform."
                )}
                {createTimepoint(
                    2024, 
                    "Fourth iteration of Peko", 
                    "This iteration was more of a revision of the previous iteration rather than a complete different piece of software. This iteration provided both the seamless developer experience and cross-platform capability I sought after. After finishing this iteration, I knew Peko was ready for the world's eyes."
                )}
                {createTimepoint(
                    2024, 
                    "Peko website and social media updated", 
                    "To enhance the new generation of Peko and give it a more proffessional and modern look, the online presence was updated."
                )}
                {createTimepoint(
                    2024, 
                    "Peko v0.9 released", 
                    "The first beta version of Peko was released, bringing multiple complex libraries, developer support, and features to the project."
                )}
            </ul>
        </>
    );
}