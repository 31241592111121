import './Styles/global.scss';

// React imports
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './Layout';
import Page from './Components/Page';

// Pages
import Main from './Pages/Main';
import Features from './Pages/Features';
import Story from './Pages/Story';
import Socials from './Pages/Socials';
import Contact from './Pages/Contact';


export default function App(router) {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" Component={Layout}>
                    <Route index element={<Page key={"/"} page={Main} />} />
                    <Route path="/features" element={<Page key={"/features"} page={Features} />}></Route>
                    <Route path="/story" element={<Page key={"/story"} page={Story} />}></Route>
                    <Route path="/socials" element={<Page key={"/socials"} page={Socials} />}></Route>
                    <Route path="/contact" element={<Page key={"/contact"} page={Contact} />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}