import React from 'react';
import { Outlet } from "react-router-dom";
// import { Helmet } from "react-helmet";

import { Footer } from './Components/Bars/Footer/Footer';
import Navbar from './Components/Bars/Navbar/Navbar';


class Layout extends React.Component {
    render() {
        return (
            <>
                <Navbar></Navbar>
                <Outlet />
                <Footer />
            </>
        )
    }
}

export default Layout;