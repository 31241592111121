import "../Styles/Socials.scss"

// React imports
import { React } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faInstagram, faReddit, } from "@fortawesome/free-brands-svg-icons";

export default function Socials() {
    let youtube_embed = (<iframe width="560" height="315" src="https://www.youtube.com/embed/1wtENvE79YQ?si=u-GexNbwoOyZlbYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>);
    let instagram_embed = (<iframe title="peko insta" class="instagram-media instagram-media-rendered" id="instagram-embed-0" src="https://www.instagram.com/peko_official/embed/?theme=dark" allowtransparency="true" allowfullscreen="true" frameborder="0" height="425" data-instgrm-payload-id="instagram-media-payload-0" scrolling="no" style={{"width": "calc(100% - 2px)", "background-color": "black", "border": "1px solid rgb(219, 219, 219)", "box-shadow": "none", "display": "block", "min-width": "326px", "padding": "0px"}}></iframe>)
    let reddit_embed = (<iframe title="peko subreddit" id="reddit-embed" src="https://www.redditmedia.com/r/PekoUI?ref\_source=embed\&amp;ref=share\&amp;embed=true" sandbox="allow-scripts allow-same-origin allow-popups" style={{border: "none"}}></iframe>);

    return (
        <>
            <Helmet>
                <title>Peko - Socials</title>
                <meta property="og:title" content="Peko - Socials" />
                <meta property="og:description" content="Access the socials for Peko to get updates and news on all things Peko" />
                <meta name="description" content="Access the socials for Peko to get updates and news on all things Peko" />
                <meta property="og:url" content="https://pekoui.com/socials" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="header-bar">
                <h1>Get In The Loop</h1>
                <hr></hr>
            </div>
            <div className="social-card">
                <div className="details">
                    <span className="card-title">Quick news and stories through Instagram</span>
                    <span className="card-details">Our Instagram page is the hub for quick info on Peko, from tutorials to important announcements. Additionally, updates regarding Peko will often be posted here.</span>
                    <div className="social-link"><FontAwesomeIcon style={{color: "red"}} icon={faInstagram} /><Link target="_blank" to="https://www.instagram.com/peko_official">peko_official</Link></div>
                </div>
                <div className="embed">{instagram_embed}</div>
            </div>
            <div className="social-card">
                <div className="details">
                    <span className="card-title">Want more depth, check out our Youtube</span>
                    <span className="card-details">Our youtube channel is a great place for longer, more in-depth content like tutorials and informational videos.</span>
                    <div className="social-link"><FontAwesomeIcon id="youtube-icon" className="icon" icon={faYoutube} /><Link target="_blank" to="https://youtube.com/@peko.official">peko.official</Link></div>
                </div>
                <div className="embed">
                {youtube_embed}
                </div>
            </div>
            <div className="social-card">
                <div className="details">
                    <span className="card-title">Participate in discussion and announcements through our Reddit</span>
                    <span className="card-details">Our Reddit community provides many opportunities to view what is going on in the Peko community and participate in discussions.</span>
                    <div className="social-link"><FontAwesomeIcon id="reddit-icon" className="icon" icon={faReddit} /><Link target="_blank" to="https://www.reddit.com/r/PekoUI/">r/PekoUI</Link></div>
                </div>
                <div className="embed">
                {reddit_embed}
                </div>
            </div>
        </>
    )
}