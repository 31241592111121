import "../Styles/Main.scss"

// React imports
import {React, useEffect } from "react";
import { Helmet } from "react-helmet";

// Components used
import { NavButton } from "../Components/Buttons/NavButton/NavButton";
import { NavLink } from "react-router-dom";

// Images used
import codeimage1 from "../Images/codeimage1.png";
import codeimage2 from "../Images/codeimage2.png";
import codeimage3 from "../Images/codeimage3.png";
import socials from "../Images/socials.png";
import headshot from "../Images/headshot.png";
import applicationbig from "../Images/applicationbig.png";

export default function Main() {
    useEffect(() => {
        const points = document.querySelectorAll(".point");
        const display = () => {
            const triggerBottom = (window.innerHeight / 5) * 4;

            points.forEach((point) => {
                const topOfContent = point.getBoundingClientRect().top;

                if(topOfContent < triggerBottom) {
                    point.classList.add("show")
                } else {
                    point.classList.remove("show")
                }
            })
        };

        display();

        window.addEventListener("scroll", display)
    })
    
    return (
        <>
        <Helmet>
            <title>Peko</title>
            <meta property="og:title" content="Peko" />
            <meta property="og:description" content="Welcome to Peko, a powerful crossplatform application development framework, enabling developers to swiftly and easily create full stack applications for all major platforms" />
            <meta name="description" content="Welcome to Peko, a powerful crossplatform application development framework, enabling developers to swiftly and easily create full stack applications for all major platforms" />
            <meta property="og:url" content="https://pekoui.com/" />
            <meta property="og:type" content="website" />
        </Helmet>
        <div id="main-entrance">
            <div id="main-title">Redefining app development</div>
            <img id="application-pics" src={applicationbig} alt="Applications built in Peko"></img>
        </div>
        <div className="shadow">
            <div id="definition-card">
                <div id="title">
                    <span id="title-content"><span className="title-piece">Pe</span><span id="dot"></span><span className="title-piece">ko</span></span>
                    <span id="sub">/p&#275;k&#333;/</span>
                </div>
                <div id="content">
                    <span id="word">noun</span>
                    <span id="description">A next-generation toolset that removes all the hassle behind cross-platform development, allowing for one codebase to implement applications for all major platforms.</span>
                </div>
            </div>
        </div>
        <div id="main-points">
            <div className="point">
                <div className="verbiage">
                    <div className="content-container">
                        <div className="header">
                            <span className="name">cross-platform</span>
                            <span className="title">Powerful cross-platform compiler</span>
                        </div>
                        <div className="content">Never worry about missing out on users. With Peko, your app will run on any modern device.</div>
                    </div>
                    <div className="link-container">
                        <NavButton to="/features#cross">Learn more</NavButton>
                    </div>
                </div>
                <img className="image" src={codeimage2} alt="showcasing cross compilation"></img>
            </div>
            <div className="point">
                <img className="image" src={codeimage1} alt="showcasing cross compilation"></img>
                <div className="verbiage">
                    <div className="content-container">
                        <div className="header">
                            <span className="name">ease of development</span>
                            <span className="title">Extensive, high-caliber, simplistic</span>
                        </div>
                        <div className="content">Normally these words would never be used to describe something, but with Peko, these words perfectly describe your developer experience.</div>
                    </div>
                    <div className="link-container">
                        <NavButton to="/features#pekoscript">See why</NavButton>
                    </div>
                </div>
            </div>
            <div className="point">
                <div className="verbiage">
                    <div className="content-container">
                        <div className="header">
                            <span className="name">safety</span>
                            <span className="title">Error handling and data protection</span>
                        </div>
                        <div className="content">The compilation process and runtime libraries put Peko through extensive and descriptive error handling and ensures protection of sensitive information.</div>
                    </div>
                    <div className="link-container">
                        <NavButton to="/features#tooling">Learn more</NavButton>
                    </div>
                </div>
                <img className="image" src={codeimage3} alt="showcasing cross compilation"></img>
            </div>
        </div>
        <div className="learn-more">
            <div className="card">
                <h1>Story behind invention</h1>
                <img alt="the creator of Peko, Preston Brown" src={headshot}></img>
                <span>Learn more about this high-caliber software developed by a 14 year old.</span>
                <NavLink to="/story">Learn more</NavLink>
            </div>
            <div className="card">
                <h1>Get involved</h1>
                <img alt="reddit, instagram, and youtube logos" src={socials}></img>
                <span>Learn how to stay up to date and get involved with Peko.</span>
                <NavLink to="#">Learn more</NavLink>
            </div>
        </div>
        <div className="fomo-section">
            <h1>Don't miss out on the opportunity</h1>
            <span className="fomo-content">Peko offers tooling that will interrupt the UI design market. Current options to design cross-platform applications come with downfalls, whether it be complicated build processes, a lack of a powerful UI, or even a lack of simplicity. Peko solves all these problems, simplifying both the development and build steps so you can focus on perfecting your application.</span>
            <NavButton to="/features">See how Peko competes</NavButton>
        </div>
        </>
    )
}