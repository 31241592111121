import "../Styles/Features.scss";

// React imports
import { React, useEffect } from "react";
import { Helmet } from "react-helmet";

// Components used
import { NavLink } from "../Components/Links/NavLink/NavLink";

// Images used
import application from "../Images/application.png";
import supported from "../Images/supported.png";
import ide from "../Images/ide.png";
import cli from "../Images/cli.png";

export default function Features() {
    useEffect(() => {
        const points = document.querySelectorAll(".feature");
        const display = () => {
            const triggerBottom = (window.innerHeight / 5) * 4;

            points.forEach((point) => {
                const topOfContent = point.getBoundingClientRect().top;

                if(topOfContent < triggerBottom) {
                    point.classList.add("show")
                } else {
                    point.classList.remove("show")
                }
            })
        };

        display();

        window.addEventListener("scroll", display)
    })
    
    return (
        <>
            <Helmet>
                <title>Peko - Features</title>
                <meta property="og:title" content="Peko" />
                <meta property="og:description" content="Learn more about the many features which enable Peko to be a leading edge technology in the crossplatform development industry" />
                <meta name="description" content="Learn more about the many features which enable Peko to be a leading edge technology in the crossplatform development industry" />
                <meta property="og:url" content="https://pekoui.com/features" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div id="feature-entrance">
                <div id="content">
                    <span id="title">Powerful features for powerful development</span>
                    <img alt="shocases application made in peko with simple code" id="image" src={application}></img>
                </div>
            </div>
            <div className="pekoscript" id="pekoscript">
                <div className="header">
                    <h1>Introducing Pekoscript, the language behind Peko</h1>
                    <span>Pekoscript is a diverse language, with features to satisfy the begginner, intermediate, and advanced developer.</span>
                </div>
                <div className="feature-list">
                    <div className="feature">
                        <div className="feature-content">
                            <div className="top-space"></div>
                            <h1>Runtime security</h1>
                            <span>The last thing you want is your app to fail miserably when your users are interacting with it. Pekoscript's advanced optional type system allows for seamlessly integrating and catching errors during runtime without crashing the app. Pekoscript's encrypted strings allow for the hiding of credentials in an application so hackers cannot uncover them.</span>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="feature-content">
                            <div className="top-space"></div>
                            <h1>Advanced features</h1>
                            <span>When designing an application, having a clean and powerful codebase is important. A clean codebase is easy to debug for errors and allows for accelerated development. With features like generics, classes, iterators, and more, Peko enables virtually any data structure to be represented elegantly, and allows for a beautiful yet simple codebase.</span>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="feature-content">
                            <div className="top-space"></div>
                            <h1>Extensive libraries</h1>
                            <span>Peko ships with a plethora of libraries, for various purposes, from parsing xml and json data, to creating advanced UIs, to connecting to a database and encrypting information. This extensive list of built-in libraries makes developing a feature-rich application a breeze, and accelrates application development.</span>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="feature-content">
                            <div className="top-space"></div>
                            <h1>Native feel</h1>
                            <span>Since your Peko application will be running on multiple screens, you want the UI to feel familiar to every user. To achieve a native look and feel, Peko utilizes the native web renderer for each platform. This allows your application to be very responsive and powerful, while still achieving that desired native look and feel.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cross" id="cross">
                <h1 className="header">Peko runs everywhere</h1>
                <div className="content">
                    <img className="platforms" src={supported} alt="all supported platforms"></img>
                    <span>With one simple command, your app gets transformed into a native app bundle for any platform you desire, handling the bundling of assets and icons as well. This allows you spend more time perfecting your application rather than perfecting the build process.</span>
                </div>
            </div>
            <div className="devex" id="tooling">
                <h1 className="header">Tooling makes development a breeze...</h1>
                <span className="description">That is what we believe at Peko, and why there is a multitude of tools at your disposal when developing with Peko.</span>
                <div className="devtools">
                    <div className="devtool">
                        <div className="content">
                            <div className="section1">
                                <h1>IDE Language Support</h1>
                                <span>The Pekoscript language pack for vscode converts your code editor into a supercharged Pekoscript development environment. This accelerates development and helps reduce both compile time and runtime errors.</span>
                            </div>
                            <div className="section2">
                                <NavLink target="blank" to="https://marketplace.visualstudio.com/publishers/Pekoscript">View Pekoscript extensions for VS Code</NavLink>
                            </div>
                        </div>
                        <img src={ide} alt="IDE"></img>
                    </div>
                    <div className="devtool">
                        <img src={cli} alt="CLI Example"></img>
                        <div className="content">
                            <div className="section1">
                                <h1>Advanced CLI</h1>
                                <span>The Peko CLI is extremely diverse, with the ability to create and build projects, compile individual peko files, and cross compile c, c++, and objective-c libraries for use with Peko. Additionally, the CLI gives very insightful messaging for errors and warnings, simplifying the process of tracking down errors.</span>
                            </div>
                            <div className="section2">
                                <NavLink to="/contact">Request a demo</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}