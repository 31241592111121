import { Link } from "react-router-dom";
import "./Footer.scss";

export let Footer = () => {
    return (
        <div className="footer">
            <div className="copyright">Copyright &copy; 2024 Preston Brown</div>
            <div className="links">
                <Link to="#">Legal Info</Link>
                <span className="divider"></span>
                <Link to="/contact">Contact</Link>
                <span className="divider"></span>
                <Link to="/story">About</Link>
            </div>
            <div className="country">United States</div>
        </div>
    );
}